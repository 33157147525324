<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <a-radio-group v-model="activeKey" @change="handleTabChange">
          <a-radio-button :value="TABS_KEYS.ALL_FILE">
            全部文件
          </a-radio-button>
          <a-radio-button :value="TABS_KEYS.FILE_MAP_MODE">
            地图模式
          </a-radio-button>
        </a-radio-group>
      </template>
      <template v-slot:header-right>
        <a-button
          size="small"
          :class="$style.btnSm"
          type="primary"
          v-if="
            activeKey === TABS_KEYS.ALL_FILE &&
              $p.action('IMAGE_LABELS', '/hat/file')
          "
          @click="handleCallout"
          >标签管理</a-button
        >
      </template>
      <div slot="content" :class="$style.wrapperContent">
        <div :class="$style.topWrap">
          <!-- <span :class="$style.spanDesc"
            >说明：管理plus版本安全帽已同步到云端的文件</span
          > -->
          <div
            :class="$style.formWrap"
            :style="
              isFull
                ? {
                    position: 'fixed',
                    right: '70px',
                    top: '10px',
                    zIndex: 102,
                  }
                : ''
            "
          >
            <a-range-picker
              :style="
                isFull
                  ? {
                      width: '300px',
                      background: selectInputBackground,
                    }
                  : {
                      width: '300px',
                    }
              "
              :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
              :ranges="presetTime"
              format="YYYY-MM-DD HH:mm:ss"
              v-model="search.dateRange"
              :allowClear="true"
              :placeholder="[
                $t('hat.clockRecord.startDate'),
                $t('hat.clockRecord.endDate'),
              ]"
            />
            <a-select
              :style="
                isFull
                  ? {
                      marginLeft: '10px',
                      background: selectInputBackground,
                    }
                  : {
                      marginLeft: '10px',
                    }
              "
              v-model="search.type"
              placeholder="全部类型"
            >
              <a-select-option
                v-for="item in typeList"
                :value="item.key"
                :key="item.key"
                >{{ item.label }}</a-select-option
              >
            </a-select>
            <a-select
              show-search
              v-model="search.projectId"
              :placeholder="$t('hat.managementModal.event.project')"
              :style="
                isFull
                  ? {
                      width: '200px',
                      marginLeft: '10px',
                      background: selectInputBackground,
                    }
                  : {
                      width: '200px',
                      marginLeft: '10px',
                    }
              "
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleProjectSearch"
              @change="handleProjectChange"
            >
              <!-- @search="handleSearch"
            @change="handleChange" -->
              <a-select-option
                v-for="item in projects"
                :value="item.pkId"
                :key="item.pkId"
                >{{ item.pmName }}</a-select-option
              >
            </a-select>
            <a-select
              show-search
              v-model="search.groupId"
              :placeholder="$t('hat.clockRecord.allGroups')"
              :style="
                isFull
                  ? {
                      width: '200px',
                      marginLeft: '10px',
                      background: selectInputBackground,
                    }
                  : {
                      width: '200px',
                      marginLeft: '10px',
                    }
              "
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleGroupSearch"
            >
              <a-select-option
                v-for="item in groups"
                :value="item.pkId"
                :key="item.pkId"
                >{{ item.deviceGroupName }}</a-select-option
              >
            </a-select>
            <a-input
              :style="
                isFull
                  ? {
                      width: '180px',
                      margin: '0 10px',
                      background: selectInputBackground,
                    }
                  : {
                      width: '180px',
                      margin: '0 10px',
                    }
              "
              v-model="search.content"
              placeholder="设备号/姓名/地址/标签"
            />
            <a-button
              :class="$style.btnOther"
              type="primary"
              @click="handleSearch"
              >搜索</a-button
            >
            <a-button
              :class="$style.btnOther"
              :style="
                isFull
                  ? {
                      background: selectInputBackground,
                    }
                  : ''
              "
              @click="handleReset"
              >清空</a-button
            >
            <a-button
              v-if="activeKey === TABS_KEYS.ALL_FILE"
              :class="$style.btnOther"
              type="primary"
              @click="() => handleDownload()"
              >导出</a-button
            >
          </div>
        </div>
        <!-- </a-tabs> -->
        <FileTable v-if="activeKey === TABS_KEYS.ALL_FILE" :params="search" />
        <FileScreenMap
          v-if="activeKey === TABS_KEYS.FILE_MAP_MODE"
          :params="search"
          @handleFullScreen="handleFullScreen"
        />
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { getPagePm } from '@/services/smart-hat/pm';
import { getPageByName } from '@/services/smart-hat/group';
import FileTable from './file-table.vue';
import FileScreenMap from './screen-map';
import { Emitter } from '@/utils';
import { Debounce } from 'lodash-decorators';
import { createFormModal } from '@triascloud/x-components';
import AddCallout from './marking/add-callout.vue';
import moment from 'moment';

/** @name 文件类型 */
export const FileTypeList = [
  {
    key: 'AUDIO',
    label: '音频',
  },
  {
    key: 'OTHER',
    label: '其它',
  },
  {
    key: 'PHOTO',
    label: '照片',
  },
  {
    key: 'VIDEO',
    label: '视频',
  },
];

/** @name 搜索事件的event_emit */
export const FILE_SEARCH_EVENT_EMIT = 'file-search-event';
/** @name 导出事件的event_emit */
export const FILE_DOWNLOAD_EVENT_EMIT = 'file-download-event';

/** @name 文件管理的tabs */
export const TABS_KEYS = {
  /** @name 全部文件 */
  ALL_FILE: 'ALL_FILE',
  /** @name 地图模式 */
  FILE_MAP_MODE: 'FILE_MAP_MODE',
};

@Component({
  components: {
    LayoutContent,
    FileTable,
    FileScreenMap,
  },
})
export default class HatFile extends Vue {
  activeKey = TABS_KEYS.ALL_FILE;
  TABS_KEYS = TABS_KEYS;

  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }

  handleTabChange() {
    this.handleReset();
  }

  mounted() {
    this.initProject();
  }

  handleSearch() {
    Emitter.$emit(FILE_SEARCH_EVENT_EMIT, this.activeKey, this.search);
  }

  async handleCallout() {
    await createFormModal(() => <AddCallout />, {
      width: 650,
      title: '标签管理',
    });
  }

  @Debounce(500)
  handleDownload() {
    Emitter.$emit(FILE_DOWNLOAD_EVENT_EMIT, this.search);
  }
  handleReset() {
    this.search = {
      type: undefined,
      content: undefined,
      dateRange: [],
      projectId: undefined,
      groupId: undefined,
    };
    Emitter.$emit(FILE_SEARCH_EVENT_EMIT, this.activeKey, this.search);
  }

  search = {
    type: undefined,
    content: undefined,
    dateRange: [],
    projectId: undefined,
    groupId: undefined,
  };

  typeList = FileTypeList;

  projects = [];
  async initProject(search) {
    let params = {};
    if (search) {
      params.input = search;
    } else {
      delete params.input;
    }
    const { records } = await getPagePm(params);
    const arr = [
      {
        pmName: '全部',
        pkId: '',
      },
      {
        pmName: '未加入项目',
        pkId: 'NOT_JOIN',
      },
    ];
    this.projects = arr.concat(records);
  }
  async handleProjectSearch(search) {
    await this.initProject(search);
  }
  groups = [];
  async handleProjectChange(projectId, search) {
    let params = {};
    if (projectId === '') {
      params = {};
    } else {
      params = {
        projectId: projectId,
      };
    }
    if (typeof search === 'string') {
      if (search) {
        params.input = search;
      } else {
        delete params.input;
      }
    }
    const { records } = await getPageByName(params);
    const arr = [
      {
        deviceGroupName: '未加入编组',
        pkId: 'NOT_JOIN',
      },
    ];
    this.groups = arr.concat(records);
    this.search.deviceGroupIds = [];
  }
  async handleGroupSearch(search) {
    await this.handleProjectChange(this.search.projectId, search);
  }
  selectInputBackground = '#fff';
  isFull = false;
  handleFullScreen(flag) {
    this.isFull = flag;
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  :global {
    .ant-btn-sm {
      height: 24px;
    }
  }
  .btnSm {
    margin-right: 10px;
  }
  .wrapperContent {
    padding: 20px 20px 10px 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btnOther + .btnOther {
    margin-left: 10px;
  }
}
.spanDesc {
  color: var(--font-info);
  font-size: 14px;
  margin-right: 20px;
}
.formWrap {
  margin-bottom: 10px;
}
.topWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
