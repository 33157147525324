<template>
  <div
    :class="$style.container"
    :style="
      isFull
        ? {
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 101,
          }
        : ''
    "
  >
    <!-- <div :class="$style.mask" v-if="showMask"></div> -->
    <div :class="$style.map" id="map"></div>
    <a-icon
      :type="isFull ? 'shrink' : 'arrows-alt'"
      :class="$style.fullScreenIcon"
      @click="handleFullScreen"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  funAMap,
  drawCharts,
  formatToApi,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { FILE_SEARCH_EVENT_EMIT, TABS_KEYS } from '@/views/hat/file/index.vue';
import { fileMapList } from '@/services/smart-hat/file';
import { Debounce } from 'lodash-decorators';
import { createDrawer } from '@triascloud/x-components';
import FileDetail from './file-detail.vue';
import { getGeoFenceZoneList } from '@/services/smart-hat/screen';
import { UploadService } from '@triascloud/services';
import { Emitter } from '@/utils';
import { delay } from '@triascloud/utils';
import AudioIcon from '!!svg-inline-loader!./map-icon/audio-icon.svg';
import PlayIcon from '!!svg-inline-loader!./map-icon/play-icon.svg';
import FileIcon from '!!svg-inline-loader!./map-icon/file-icon.svg';

let map, AMap;
@Component()
export default class FileScreenMap extends Vue {
  @Prop({ type: Object, default: () => {} }) params;

  mounted() {
    this.initMap();
    Emitter.$on(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
  }
  beforeDestroy() {
    Emitter.$off(FILE_SEARCH_EVENT_EMIT, this.handleSearch);
    if (this.modalDrawer) {
      this.modalDrawer.handleClosed();
    }
  }
  handleSearch(type, obj) {
    this.isLoad = false;
    this.fileMakers.length && this.clearFileMakers();
    this.chartList.length && map.remove(this.chartList);
    map.setZoom(4);
    map.setCenter([116.397428, 39.90923]);
    if (type === TABS_KEYS.FILE_MAP_MODE) {
      this.searchParams.content = obj.content;
      this.searchParams.groupId = obj.groupId;
      this.searchParams.projectId = obj.projectId;
      this.searchParams.type = obj.type;
      this.searchParams.startTime = obj.dateRange[0]
        ? this.$moment(obj.dateRange[0]).valueOf()
        : '';
      this.searchParams.endTime = obj.dateRange[1]
        ? this.$moment(obj.dateRange[1]).valueOf()
        : '';
      this.loadFilePositions();
    }
    this.isLoad = true;
  }
  showMask = false;
  async initMap() {
    this.showMask = true;
    AMap = await funAMap();
    const { mode } = this.$store.state.crossStorage.skin;
    map = new AMap.Map('map', {
      zoom: 4,
      center: [116.397428, 39.90923],
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    map.on('complete', () => {
      // 解决地图闪屏问题
      // setTimeout(() => {
      this.showMask = false;
      // }, 500);
    });
    this.attachMapEvents();
    this.zoomChange();
    this.loadFilePositions();
  }
  attachMapEvents() {
    map.on('resize', this.dialogClose);
    map.on('dblclick', this.dialogClose);
    // map.on('mapmove', this.dialogClose);
    map.on('hotspotclick', this.dialogClose);
    map.on('rotatechange', this.dialogClose);
    map.on('mousewheel', this.dialogClose);
    map.on('dragstart', this.dialogClose);
    map.on('dragging', this.dialogClose);
    map.on('dragend', this.dialogClose);
  }
  isLoad = true;
  zoomChange() {
    const change = async e => {
      if (!this.zoomChangeStop) {
        this.dialogClose();
      }
      let zoom = e.target.getZoom();
      this.centerZoom = zoom;
      if (zoom < 2.5) {
        // 世界
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 2.5 && zoom < 4.8) {
        // 全国
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 4.8 && zoom < 5.8) {
        // 省
        this.searchParams.level = 'PROVINCE';
      }
      if (zoom >= 5.8 && zoom < 8.8) {
        // 市
        this.searchParams.level = 'CITY';
      }
      if (zoom >= 8.8) {
        // 区
        this.searchParams.level = 'ADDRESS';
      }
      this.isLoad && !this.zoomChangeStop && this.loadFilePositions();
    };
    map.on('zoomchange', change);
  }
  searchParams = {
    content: '',
    groupId: '',
    projectId: '',
    latMax: '',
    latMin: '',
    lngMax: '',
    lngMin: '',
    level: 'PROVINCE',
    type: '',
    startTime: '',
    endTime: '',
  };
  fileMakers = [];
  @Debounce(800)
  async loadFilePositions() {
    this.fileMakers.length && this.clearFileMakers();
    this.chartList.length && map.remove(this.chartList);
    let northWest = map.getBounds().getNorthWest(); // 西北
    let southEast = map.getBounds().getSouthEast(); // 东南
    // 当纬度大于180时是否自动修正：传false自动修正，true为不修正
    const lnglatMax = new AMap.LngLat(southEast.lng, northWest.lat, true);
    const lnglatMin = new AMap.LngLat(northWest.lng, southEast.lat, true);
    this.searchParams.latMax = lnglatMax.lat;
    this.searchParams.latMin = lnglatMin.lat;
    this.searchParams.lngMax = lnglatMax.lng;
    this.searchParams.lngMin = lnglatMin.lng;
    this.searchParams.startTime = this.params.dateRange[0]
      ? this.$moment(this.params.dateRange[0]).valueOf()
      : '';
    this.searchParams.endTime = this.params.dateRange[1]
      ? this.$moment(this.params.dateRange[1]).valueOf()
      : '';
    const fileList = await fileMapList(this.searchParams);
    this.createPositionMakers(fileList);
  }
  createPositionMakers(fileList) {
    let positions = fileList.map(item => {
      let dom = '';
      // item.total = item.total > 99 ? '99+' : item.total;
      const label = `<div class="label ${item.total ? '' : 'label__hidden'}">${
        item.total
      }</div>`;
      switch (item.type) {
        case 'PHOTO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            <img src="${item.path}" />
            ${label}
          </div>`;
          break;
        case 'VIDEO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            <div class="video-container">
              ${PlayIcon}
              <img src="${item.path}" />
            </div>
            ${label}
          </div>`;
          break;
        case 'AUDIO':
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            ${AudioIcon}
            ${label}
          </div>`;
          break;
        default:
          dom = `<div class="custom-content-marker" data-id="${item.fileId}">
            ${FileIcon}
            ${label}
          </div>`;
      }
      return {
        position: item.positionList[0].split(','),
        dom,
        country: item.country,
        province: item.province,
        city: item.city,
        district: item.district,
        township: item.township,
        address: item.address,
      };
    });
    positions.forEach((item, index) => {
      this.fileMakers[index] = new AMap.Marker({
        map: map,
        content: item.dom,
        position: [+item.position[0], +item.position[1]],
        offset: new AMap.Pixel(-13, -30),
      });
      this.fileMakers[index].on('click', e => {
        let position = e.target._opts ? e.target._opts.position : e.lnglat;
        map.setZoomAndCenter(this.nextAreaZoom(), position);
        this.openFileDetail(positions[index]);
      });
    });
  }
  centerZoom = 0;
  /** @name 下一层级地区 */
  nextAreaZoom() {
    if (this.centerZoom < 5) {
      this.centerZoom = 7;
    } else if (this.centerZoom <= 7) {
      this.centerZoom = 9;
    } else if (this.centerZoom <= 9) {
      this.centerZoom = 12;
    } else if (this.centerZoom <= 12) {
      this.centerZoom = 15;
    } else if (this.centerZoom <= 15) {
      this.centerZoom = 17;
    } else if (this.centerZoom <= 17) {
      this.centerZoom = 18;
    }
    return this.centerZoom;
  }
  clearFileMakers() {
    this.fileMakers.forEach(item => {
      map.remove(item);
    });
  }
  choseFileId = '';
  async mapMove(opts) {
    map.clearMap();
    map.setZoomAndCenter(18, [opts.lng, opts.lat]);
    this.choseFileId = opts.pkId;
    this.createPositionMakers(opts.fileList);
    this.changeDrawerTitle(opts.title);
    await this.$nextTick();
    await delay(200);
    this.findMarker();
  }
  findMarker() {
    const nodes = document.querySelectorAll('.custom-content-marker');
    nodes.forEach(marker => {
      const dataId = marker.getAttribute('data-id');
      if (this.choseFileId === dataId) {
        marker.setAttribute('class', 'custom-content-marker active');
      } else {
        marker.setAttribute('class', 'custom-content-marker');
      }
    });
  }
  dialogClose() {
    if (this.modalDrawer) {
      this.modalDrawer.handleClosed();
      this.loadFilePositions();
    }
  }
  zoomChangeStop = false;
  modalDrawer = null;
  changeDrawerTitle(title = '') {
    const el = document.querySelector(
      '.fileDetail .ant-drawer-title .x-ellipsis',
    );
    el.innerHTML = title;
  }
  openFileDetail(data) {
    this.dialogClose();
    this.zoomChangeStop = true;
    const params = {
      country: data.country,
      province: data.province,
      city: data.city,
      district: data.district,
      township: data.township,
      address: data.address,
      startTime: this.searchParams.startTime,
      endTime: this.searchParams.endTime,
      content: this.searchParams.content,
      groupId: this.searchParams.groupId,
      projectId: this.searchParams.projectId,
      type: this.searchParams.type,
    };
    this.modalDrawer = createDrawer(
      () => (
        <FileDetail
          params={params}
          onToPosition={v => this.showFence(v)}
          mapMove={e => this.mapMove(e)}
        />
      ),
      {
        title: '文件详情',
        width: 640,
        /** @description wrapClassName关联到更改drawer弹窗的标题函数，changeDrawerTitle() */
        wrapClassName: 'fileDetail',
        mask: false,
        onClose: () => {
          this.zoomChangeStop = false;
          this.isLoad = true;
          this.modalDrawer = null;
          this.loadFilePositions();
        },
      },
    );
  }
  zoneArray = [];
  chartList = [];
  async showFence(obj) {
    this.isLoad = false;
    this.chartList.length && map.remove(this.chartList);
    const param = {
      geofenceIds: obj.geofenceId,
    };
    const data = await getGeoFenceZoneList(param);
    this.zoneArray = formatToApi(data);
    this.chartList = drawCharts(this.zoneArray, AMap, (...args) =>
      mouseEvent.call(null, ...args, map),
    );
    map.add(this.chartList);
    map.setZoom(16);
    map.setCenter([obj.lng, obj.lat]);
    obj.path = await this.getFileUrl(obj);
    obj.positionList = [`${obj.lng},${obj.lat}`];
    await this.clearFileMakers();
    await this.createPositionMakers([obj]);
  }
  async getFileUrl(obj) {
    let path = obj.path;
    if (obj.type === 'VIDEO') {
      path =
        path + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_50,h_50,m_fast';
    } else if (obj.type === 'PHOTO') {
      path = path + '?x-oss-process=image/resize,h_50,m_lfit';
    }
    const customService = new UploadService('/oss/iot/oss');
    const abPath = await customService.getAuth(path);
    return abPath;
  }
  isFull = false;
  handleFullScreen() {
    this.isFull = !this.isFull;
    this.$emit('handleFullScreen', this.isFull);
  }
}
</script>
<style lang="less" module>
.container {
  flex: 1;
  position: relative;
  .map {
    height: 100%;
    width: 100%;
  }
  .mask {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    z-index: 99;
  }
  .fullScreenIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 36px;
    color: var(--primary);
    cursor: pointer;
  }
}
:global {
  [class*='triascloud-theme-'].dark {
    --bg-transparent-80: rgba(63, 63, 63, 0.8);
  }
  [class*='triascloud-theme-'].tint {
    --bg-transparent-80: rgba(255, 255, 255, 0.8);
  }
  .fileDetail {
    .ant-drawer-body {
      background-color: var(--bg-transparent-80);
      padding: 0;
    }
    .ant-drawer-content {
      background-color: transparent;
    }
  }
  .custom-content-marker {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px #cdcdcd;
    border-radius: 6px;
    &.active {
      border: 2px solid var(--font-active);
    }
    img {
      width: 88%;
      height: 88%;
      border-radius: 6px;
      object-fit: cover;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .video-container {
      width: 88%;
      height: 88%;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      & > img {
        height: 100%;
        width: 100%;
      }
      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        color: #fff;
      }
    }
    .label {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      min-width: 20px;
      padding: 0 2px;
      font-size: 12px;
      background: var(--primary);
      border-radius: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
    .label__hidden {
      display: none;
    }
  }
}
</style>
