<template>
  <x-scroll-view
    :class="$style.container"
    :disabled="isEnd"
    :lowerThreshold="80"
    :immediateCheck="false"
    ref="Ref"
    @scrolltolower="loadMoreFiles"
  >
    <a-timeline>
      <a-timeline-item
        v-for="(item, index) in fileList"
        :key="index"
        :ref="'timeline' + index"
      >
        <div :class="$style.time">
          {{ $moment(item.fileDay).format('YYYY年MM月DD') }}
        </div>
        <div :class="$style.name" @click="toPosition(item)">
          {{ item.address }}
        </div>
        <div :class="$style.fileList">
          <template>
            <a-tooltip
              placement="bottom"
              v-for="fileItem in item.pathList.fileList"
              :key="fileItem.pkId"
              :mouseEnterDelay="1"
            >
              <div :class="$style.fileItem" @click="handleShowFile(fileItem)">
                <x-oss-image
                  :class="$style.thumbnail"
                  basePath="/oss/iot/oss"
                  v-if="fileItem.type === 'PHOTO'"
                  :ossPath="
                    fileItem.path + '?x-oss-process=image/resize,h_100,m_lfit'
                  "
                />
                <video-thumbnail
                  :class="$style.thumbnail"
                  basePath="/oss/iot/oss"
                  :ossPath="fileItem.path"
                  size="100"
                  :hasClick="false"
                  v-else-if="fileItem.type === 'VIDEO'"
                />
                <div :class="$style.iconBox" v-else>
                  <x-icon
                    :type="FILE_ICON[fileItem.type]"
                    :class="$style.icon"
                  />
                </div>
              </div>
              <div
                slot="title"
                style="cursor: pointer;"
                @click="openManageModal(fileItem.idxDeviceId)"
              >
                <div>
                  创建：{{
                    `${fileItem.userName}${
                      fileItem.roles.length
                        ? `（${fileItem.roles[0].roleName}）`
                        : ''
                    }`
                  }}
                </div>
                <div>设备：{{ fileItem.deviceName }}</div>
                <div>
                  创建时间：{{
                    fileItem.fileTime
                      ? $moment(fileItem.fileTime).format('YYYY-MM-DD HH:mm:ss')
                      : ''
                  }}
                </div>
              </div>
            </a-tooltip>
          </template>
        </div>
        <div
          :class="$style.loadMore"
          @click="handleLoadMore(item)"
          v-if="item.pathList.hasNext"
        >
          加载更多
        </div>
      </a-timeline-item>
    </a-timeline>
  </x-scroll-view>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { fileMapDetail, moreFileMapDetail } from '@/services/smart-hat/file';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import { UploadService } from '@triascloud/services';
import { createModal } from '@triascloud/x-components';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { delay } from '@triascloud/utils';
import Preview from '@/views/hat/file/preview/index.vue';

/** oss图片缩放 */
const relativePath = result => {
  return result + '?x-oss-process=image/resize,h_50,m_lfit';
};
/** oss视频截帧 */
const videoPath = result => {
  return result + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_50,h_50';
};

@Component({
  components: {
    VideoThumbnail,
  },
})
export default class MapFileDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) params;
  @Prop() mapMove;
  mounted() {
    this.getFileList();
    this.addScrollEvent();
  }

  beforeDestroy() {
    this.removeScrollEvent();
  }

  anchor;
  addScrollEvent() {
    this.$refs.Ref &&
      this.$refs.Ref.$el &&
      this.$refs.Ref.$el.addEventListener('scroll', this.scrollEvent);
  }
  removeScrollEvent() {
    this.$refs.Ref &&
      this.$refs.Ref.$el &&
      this.$refs.Ref.$el.removeEventListener('scroll', this.scrollEvent);
  }
  async scrollEvent(e) {
    const top = e.target.scrollTop;
    for (const [idx, item] of this.timelines.entries()) {
      if (top > item && top < this.timelines[idx + 1]) {
        await this.anchorTop(idx);
        break;
      }
    }
  }
  /** @name 锚点到顶部 */
  async anchorTop(idx) {
    if (this.anchor !== this.fileList[idx]) {
      this.anchor = this.fileList[idx];
      const item =
        this.anchor.pathList.fileList[0] && this.anchor.pathList.fileList[0];
      if (item) {
        const customService = new UploadService('/oss/iot/oss');
        let path = item.path;
        if (item.type === 'PHOTO') {
          path = relativePath(item.path);
        } else if (item.type === 'VIDEO') {
          path = videoPath(item.path);
        }
        const image = await customService.getAuth(path);
        this.mapMove({
          lng: +item.lng,
          lat: +item.lat,
          pkId: item.pkId,
          fileList: [
            {
              path: image,
              type: item.type,
              total: this.anchor.total,
              fileId: item.pkId,
              positionList: [`${item.lng},${item.lat}`],
            },
          ],
          title: item.address,
        });
      }
    }
  }
  async loadTimelines() {
    await this.$nextTick();
    await delay(200);
    try {
      this.timelines[0] = 0;
      this.fileList.forEach((_, idx) => {
        if (this.$refs['timeline' + idx] && this.$refs['timeline' + idx][0]) {
          const height = this.$refs['timeline' + idx][0].$el.scrollHeight;
          this.timelines[idx + 1] = this.timelines[idx] + height;
        }
      });
    } catch (error) {
      window.console.error(error);
    }
  }

  FILE_ICON = {
    PHOTO: 'tc-color-file-picture',
    VIDEO: 'tc-color-file-video',
    AUDIO: 'tc-color-file-voice',
    OTHER: 'tc-color-file-unknown',
  };
  fileList = [];
  pagination = {
    current: 1,
    size: 10,
  };
  timelines = [];
  async getFileList() {
    const params = {
      ...this.pagination,
      ...this.params,
    };
    const { records } = await fileMapDetail(params);
    this.fileList = records;
    // 判断下一页是否还有数据
    this.isEnd = records.length < this.pagination.size;
    await this.loadTimelines();
  }
  handleShowFile(fileItem) {
    Preview.createModal({
      urls: fileItem.path,
      opts: [
        {
          id: fileItem.pkId,
          relatedFileList: fileItem.relatedFilePathList,
          videoTrackModel: fileItem.videoTrackModel,
          videoTrackModelWatermark: fileItem.videoTrackModelWatermark,
        },
      ],
      ossBasePath: '/oss/iot/oss',
    });
  }
  isEnd = false;
  async loadMoreFiles() {
    this.pagination.current += 1;
    const params = {
      ...this.pagination,
      ...this.params,
    };
    const { records } = await fileMapDetail(params);
    this.fileList = [...this.fileList, ...records];
    this.isEnd = records.length < this.pagination.size;
    await this.loadTimelines();
  }
  async handleLoadMore(record) {
    record.current = record.current ? record.current : 1;
    record.current++;
    const fileList = record.pathList.fileList;
    const params = {
      address: record.address,
      lastPkId: fileList[fileList.length - 1].pkId,
      startTime: record.fileDay,
      endTime: record.fileDay + 86399000,
      content: this.params.content,
      current: record.current,
      size: 10,
      groupId: this.params.groupId,
      projectId: this.params.projectId,
      type: this.params.type,
    };
    const data = await moreFileMapDetail(params);
    record.pathList.hasNext = data.hasNext;
    record.pathList.fileList = [...record.pathList.fileList, ...data.fileList];
    await this.loadTimelines();
  }
  toPosition(record) {
    this.$emit('toPosition', {
      total: record.total,
      ...record.pathList.fileList[0],
    });
  }
  openManageModal(id) {
    if (!id) {
      this.$message.error('设备不存在！');
    }
    if (this.$p.action('READ', '/hat/device')) {
      createModal(() => <ManageModel record={{ deviceId: id }} />, {
        width: 1280,
        title: this.$t('hat.deviceManagement.manage'),
        maskClosable: false,
        className: this.$style.ManageModel,
      });
    }
  }
}
</script>
<style lang="less" module>
.container {
  padding: 20px;
  // background-color: var(--body-bg);
  .time {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
  }
  .name {
    font-size: 16px;
    color: var(--primary);
    text-decoration-line: underline;
    cursor: pointer;
  }
  .fileList {
    margin: 4px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .fileItem {
      .thumbnail {
        height: 100px;
        width: 100px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 8px;
      }
      .iconBox {
        height: 100px;
        width: 100px;
        border-radius: 8px;
        background: var(--block-bg);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px 0px #e1e1e1;
        .icon {
          font-size: 75px;
        }
      }
    }
  }
  .loadMore {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
